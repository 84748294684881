import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import aboutStyles from './About.module.scss';
import CommaSVG from '../../assets/svg/comma-deco.svg';
import HollowGridBallSVG from '../../assets/svg/hollow-grid-ball.svg';
import AndroidSVG from '../../assets/svg/android-logo.svg';
import CodeSVG from '../../assets/svg/code-logo.svg';
import DatabaseSVG from '../../assets/svg/database.svg';
import DroneSVG from '../../assets/svg/drone.svg';
import GitflowSVG from '../../assets/svg/gitflow.svg';
import JiraSVG from '../../assets/svg/jira.svg';
import LockSVG from '../../assets/svg/lock.svg';
import NetworkSVG from '../../assets/svg/network.svg';
import NodeJsSVG from '../../assets/svg/node-js.svg';
import OkrSVG from '../../assets/svg/okr.svg';
import ReactSVG from '../../assets/svg/react-logo.svg';
import ScrumSVG from '../../assets/svg/scrum.svg';
import DockerSVG from '../../assets/svg/docker.svg';
import AvatarLogoSVG from '../../assets/svg/avatar-clip-path.svg';

type Props = {
  title?: string,
  children: React.Node,
};

const About = ({ title, children }: Props) => {
  return (
    <div className={aboutStyles['about']}>
      <div className={aboutStyles['about__inner']}>
        <section className={aboutStyles['opening-section']}>
          <Parallax
            className={aboutStyles['opening-section__hollow-grid-ball']}
            y={[-20, 40]}
            tagOuter='figure'
          >
            <HollowGridBallSVG />
          </Parallax>
          <div className={aboutStyles['opening-section__inner']}>
            <Parallax y={[-20, 20]}>
              <div
                className={
                  aboutStyles['opening-section__inner__avatar-container']
                }
              >
                <AvatarLogoSVG />
                <img
                  className={
                    aboutStyles[
                      'opening-section__inner__avatar-container__image'
                    ]
                  }
                  alt='andreson-avatar'
                  src='/avatar/andreson-avatar-org.jpg'
                />
              </div>
            </Parallax>
            <div
              className={aboutStyles['opening-section__inner__title-container']}
            >
              <h1
                className={
                  aboutStyles['opening-section__inner__title-container__h1']
                }
              >
                {"Hey!\nI'm Andreson"}
                <CommaSVG
                  className={
                    aboutStyles[
                      'opening-section__inner__title-container__h1__deco-1'
                    ]
                  }
                />
              </h1>
              <span
                className={
                  aboutStyles['opening-section__inner__title-container__span']
                }
              >
                FRONT-END ENGINEER/REACT.js
              </span>
            </div>
          </div>
        </section>
        <section className={aboutStyles['about-me-section']}>
          <div className={aboutStyles['about-me-section__inner']}>
            <h2>ABOUT</h2>
            <i>
              I pursue to be a staff engineer equipped with both expertise and
              entrepreneurship traits.
            </i>
            <br />
            <p>
              &emsp;&emsp;Hi there! Andreson is here with 4+ years of product
              development experience. My participation in constructing both Web
              and Android products from scratch solidified my capacity to
              provide user-centered solutions. I adopted the MVVM software
              architecture in Android and implemented the server-side-rendering
              framework of Next.js on the web, and both successfully delivered a
              better user experience.
            </p>
            <br />
            <p>
              &emsp;&emsp;The practice of releasing one hybrid Android App and
              two web products formed my technical and commercial comprehension.
              It empowered me to represent my company for over three fundraising
              business pitches. I expect myself not only to be a tech
              professional but a practitioner with entrepreneurship!
            </p>
          </div>
        </section>
        <section className={aboutStyles['front-end-skills-section']}>
          <div className={aboutStyles['front-end-skills-section__inner']}>
            <h2>FRONT-END SKILLS</h2>
            <div
              className={aboutStyles['front-end-skills-section__inner__skills']}
            >
              <div
                className={
                  aboutStyles['front-end-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'front-end-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <CodeSVG />
                </div>

                <p>HTML/CSS/JavaScript</p>
                <ul>
                  <li>Pug</li>
                  <li>Sass/SCSS</li>
                  <li>ECMAScript 6, 7, 8 </li>
                  <li>TypeScript</li>
                </ul>
              </div>
              <div
                className={
                  aboutStyles['front-end-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'front-end-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <ReactSVG />
                </div>

                <p>React.js Ecosystem</p>
                <ul>
                  <li>Redux</li>
                  <li>Redux-Saga</li>
                  <li>Reselect</li>
                  <li>Styled-Components</li>
                  <li>Styled-System</li>
                  <li>React Hooks API</li>
                </ul>
              </div>
              <div
                className={
                  aboutStyles['front-end-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'front-end-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <AndroidSVG />
                </div>

                <p>Android Ecosystem</p>
                <ul>
                  <li>Android Architecture Component(MVVM)</li>
                  <li>LiveData</li>
                  <li>Room</li>
                  <li>Retrofit2</li>
                  <li>RxJava2</li>
                  <li>Dagger2</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className={aboutStyles['back-end-skills-section']}>
          <div className={aboutStyles['back-end-skills-section__inner']}>
            <h2>BACK-END SKILLS</h2>
            <div
              className={aboutStyles['back-end-skills-section__inner__skills']}
            >
              <div
                className={
                  aboutStyles['back-end-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'back-end-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <NetworkSVG />
                </div>
                <p>Network</p>
                <ul>
                  <li>DNS</li>
                  <li>HTTP</li>
                  <li>XMPP</li>
                  <li>MQTT</li>
                  <li>SIP</li>
                </ul>
              </div>
              <div
                className={
                  aboutStyles['back-end-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'back-end-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <NodeJsSVG />
                </div>
                <p>Node.js</p>
                <ul>
                  <li>RESTful API</li>
                  <li>Apollo GraphQL</li>
                  <li>Express.js</li>
                  <li>Koa.js</li>
                  <li>Nest.js</li>
                  <li>PM2</li>
                </ul>
              </div>
              <div
                className={
                  aboutStyles['back-end-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'back-end-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <DatabaseSVG />
                </div>
                <p>Database</p>
                <ul>
                  <li>MySQL</li>
                  <li>MongoDB</li>
                  <li>Firebase Realtime Database</li>
                </ul>
              </div>
              <div
                className={
                  aboutStyles['back-end-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'back-end-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <LockSVG />
                </div>
                <p>Cryptography</p>
                <ul>
                  <li>AES</li>
                  <li>RSA</li>
                  <li>ECC</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className={aboutStyles['ci-skills-section']}>
          <div className={aboutStyles['ci-skills-section__inner']}>
            <h2>CONTINUOUS INTEGRATION SKILLS</h2>
            <div className={aboutStyles['ci-skills-section__inner__skills']}>
              <div
                className={
                  aboutStyles['ci-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'ci-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <GitflowSVG />
                </div>
                <p>GitFlow</p>
              </div>
              <div
                className={
                  aboutStyles['ci-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'ci-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <DroneSVG />
                </div>
                <p>Drone</p>
              </div>
              <div
                className={
                  aboutStyles['ci-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'ci-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <DockerSVG />
                </div>
                <p>Docker</p>
              </div>
            </div>
          </div>
        </section>
        <section className={aboutStyles['management-skills-section']}>
          <div className={aboutStyles['management-skills-section__inner']}>
            <h2>MANAGEMENT SKILLS</h2>
            <div
              className={
                aboutStyles['management-skills-section__inner__skills']
              }
            >
              <div
                className={
                  aboutStyles['management-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'management-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <OkrSVG />
                </div>
                <p>OKRs</p>
              </div>
              <div
                className={
                  aboutStyles['management-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'management-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <ScrumSVG />
                </div>
                <p>Scrum</p>
              </div>
              <div
                className={
                  aboutStyles['management-skills-section__inner__skills__skill']
                }
              >
                <div
                  className={
                    aboutStyles[
                      'management-skills-section__inner__skills__skill__image-container'
                    ]
                  }
                >
                  <JiraSVG />
                </div>
                <p>Jira</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
